const CATEGORIES = [

    {
        label: 'Bouquet de Loire',
        value: '#afcb37'
    },
    {
        label: 'Champagne',
        value: '#cfaa59'
    },
    {
        label: 'France',
        value: '#2f2c6b'
    },
    {
        label: 'Jura, Savoie, Alsace',
        value: '#cfab8c'
    },

    {
        label: 'L’esprit du Sud',
        value: '#e7393e'
    },
    {
        label: 'La vie en Rose',
        value: '#f193bd'
    },
    {
        label: 'Le Beaujolais',
        value: '#b2b2b2'
    },
    {
        label: 'Le Bordelais',
        value: '#9373b1'
    },
    {
        label: 'Le Saint-Émilionnais',
        value: '#009381'
    },
    {
        label: 'Le Sud-Ouest en fête',
        value: '#0050a0'
    },
    {
        label: 'Les Artistes du Rhône',
        value: '#82368c'
    },
    {
        label: 'Les Bières',
        value: '#f7a600'
    },
    {
        label: 'Les Bulles',
        value: '#f2b82c'
    },
    {
        label: 'Les Spiritueux',
        value: '#ad184f'
    },

    {
        label: 'Méditerranéen',
        value: '#f18739'
    },
    {
        label: 'Pessac-Léognan Graves',
        value: '#009dc8'
    },
    {
        label: 'Route du Médoc',
        value: '#ea5153'
    },
    {
        label: 'Vignerons Bourguignons',
        value: '#e50064'
    },
    {
        label: 'Vins du Monde',
        value: '#ee7026'
    },
    {
        label: 'Vins en Or',
        value: '#eac747'
    },

];

export default CATEGORIES;